<template>
  <div>
    <div class="d-flex flex-row mb-3">
      <button
        :disabled="IsLoading"
        type="button"
        class="btn btn-white btn-sm text-nowrap"
        @click="fetchFeeds"
      >Reload</button>
      <button
        :disabled="IsLoading"
        type="button"
        class="d-none btn btn-primary btn-sm ml-2 text-nowrap"
        @click="createFeed"
      >Create new feed</button>

      <div class="d-none">
        <b-form-select
          v-model="sortBy"
          :options="sortOptions"
          size="sm"
          class="sort-select d-inline-block ml-2"
          @change="filterFeeds"
        ></b-form-select>
      </div>
    </div>

    <b-form-input
      v-model="searchText"
      class="d-none search-text"
      type="text"
      debounce="150"
      placeholder="Search *"
      autofocus
      autocomplete="off"
      @update="filterFeeds"
    ></b-form-input>

    <table class="table table-google table-feeds mt-2">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th class="text-right">Items</th>
          <th>Mandant</th>
          <th>URL</th>
          <th>Added</th>
          <th>Last update</th>
          <th>Next update</th>
          <th>Downloaded</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="feed in filteredFeeds" :key="feed.code">
          <td class="row-fit">{{ feed.id }}</td>
          <td class="row-fit">{{ feed.title }}</td>
          <td class="row-fit text-right">{{ feed.node_count | format }}</td>
          <td class="row-fit">{{ feed.mandant_code }}</td>
          <td class="row-fit text-nowrap">{{ feed.url }}</td>
          <td class="row-fit text-nowrap">
            <span v-if="feed.create_ts">
              {{ feed.create_ts | shortdate }} {{ feed.create_ts | time }}
            </span>
            <span v-else>Unknown</span>
          </td>
          <td class="row-fit text-nowrap">
            <span v-if="feed.update_ts">
              {{ feed.update_ts | shortdate }} {{ feed.update_ts | time }}
            </span>
            <span v-else>Unknown</span>
          </td>
          <td class="row-fit text-nowrap">
            <span v-if="feed.next_ts">
              {{ feed.next_ts | shortdate }} {{ feed.next_ts | time }}
            </span>
            <span v-else>Unknown</span>
          </td>
          <td class="row-fit text-nowrap">
            <span v-if="feed.download_ts">
              {{ feed.download_ts | shortdate }} {{ feed.download_ts | time }}
            </span>
            <span v-else>Unknown</span>
          </td>
          <!-- action -->
          <td class="d-flex text-nowrap row-fit">
            <div class="mr-1">
              <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  :disabled="feed.loading"
                  @click="manageFeedMasks(feed)"
                  title="Manage IPs"
                >
                  Manage IPs
                </button>

              <button
                  type="button"
                  class="btn btn-white btn-sm ml-1"
                  :disabled="feed.loading"
                  @click="downloadFeed(feed)"
                  title="Download feed"
                >
                  Download
                </button>
            </div>
            <div v-if="feed.editMode" class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary btn-sm mr-1"
                :disabled="feed.loading"
                @click="saveFeedChanges(feed)"
                title="Save changes"
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white btn-sm"
                :disabled="feed.loading"
                @click="cancelFeedChanges(feed)"
                title="Cancel changes"
              >
                Cancel
              </button>
            </div>
            <div v-else class="d-flex justify-content-end">
              <button
                type="button"
                class="d-none btn btn-primary btn-sm mr-1"
                :disabled="feed.loading"
                @click="feed.editMode = true"
                title="Edit feed"
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm"
                :disabled="feed.loading"
                @click="deleteMandant(feed)"
                title="Delete feed"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AdminFeedList',
  components: {},
  computed: {
    IsLoading() {
      return this.loadingCount > 0 || this.feeds.some((feed) => feed.loading);
    },
  },
  data() {
    return {
      loadingCount: 0,
      searchText: '',
      feeds: [],
      filteredFeeds: [],
      sortBy: 'code',
      sortOptions: [
        { value: 'code', text: 'Sort by: Code' },
        { value: 'title', text: 'Sort by: Title' },
      ],
    };
  },
  methods: {
    setupFeedMeta(feed) {
      feed.editMode = false;
      feed.loading = false;
    },
    filterFeeds() {
      this.filteredFeeds = this.feeds;
      // this.filteredFeeds = this.feeds.filter((feed) => (
      //   feed.editMode
      //     || feed.code === this.searchText
      //     || feed.title.toLowerCase().includes(this.searchText.toLowerCase())
      // ));
      // this.filteredFeeds = _.sortBy(this.filteredFeeds, this.sortBy);
    },
    fetchFeeds() {
      if (this.IsLoading) return;
      this.loadingCount += 1;
      this.$http
        .get('/feeds')
        .then((res) => {
          res.body.feeds.forEach((feed) => {
            this.setupFeedMeta(feed);
          });
          this.feeds = res.body.feeds;
          this.filterFeeds();
        })
        .catch((err) => {
          alert(`Failed to fetch feeds: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    createFeed() {
      if (this.IsLoading) return;
      const feedCode = prompt('Enter feed code');
      if (feedCode === null || feedCode === '') return;
      this.loadingCount += 1;
      this.$http
        .post('/feeds')
        .send({ feedCode })
        .then((res) => {
          this.setupFeedMeta(res.body.feed);
          this.feeds.push(res.body.feed);
          this.filterFeeds();
        })
        .catch((err) => {
          alert(`Failed to create new feed: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    deleteMandant(feed) {
      if (feed.loading) return;
      const confirmed = confirm(`Do you really wish to delete feed '${feed.id}'?`);
      if (!confirmed) return;

      feed.loading = true;
      this.$http
        .delete(`/feed/${feed.id}`)
        .then(() => {
          this.feeds = this.feeds.filter((_feed) => _feed.id !== feed.id);
          this.filterFeeds();
        })
        .catch((err) => {
          alert(`Failed to delete feed: ${err.response.text}`);
        })
        .finally(() => {
          feed.loading = false;
        });
    },
    saveFeedChanges(feed) {
      if (feed.loading) return;

      feed.loading = true;
      const feedChanges = {
        mandant_code: feed.mandant_code,
        url: feed.url,
      };
      this.$http
        .put(`/feeds/${feed.id}`)
        .send({ feedChanges })
        .then((res) => {
          this.setupFeedMeta(res.body.feed);
          // just to verify that changes were made
          Object.keys(feed).forEach((key) => {
            feed[key] = res.body.feed[key];
          });
          feed.editMode = false;
          this.filterFeeds();
        })
        .catch((err) => {
          alert(`Failed to save changes: ${err.response.text}`);
        })
        .finally(() => {
          feed.loading = false;
        });
    },
    cancelFeedChanges(feed) {
      if (feed.loading) return;
      feed.loading = true;
      this.$http
        .get(`/feed/${feed.id}/meta`)
        .then((res) => {
          this.setupFeedMeta(res.body.feedMeta);
          Object.keys(feed).forEach((key) => {
            feed[key] = res.body.feedMeta[key];
          });
          this.filterFeeds();
        })
        .catch((err) => {
          alert(`Failed to show live values: ${err.response.text}`);
        })
        .finally(() => {
          feed.editMode = false;
          feed.loading = false;
        });
    },
    downloadFeed(feed) {
      if (feed.loading) return;
      feed.loading = true;
      this.$http
        .get(`/feed/${feed.id}/download`)
        .then((res) => {
          this.setupFeedMeta(res.body.feedMeta);
          Object.keys(feed).forEach((key) => {
            feed[key] = res.body.feedMeta[key];
          });
          this.filterFeeds();
        })
        .catch((err) => {
          alert(`Failed to download feed: ${err.response.text}`);
        })
        .finally(() => {
          feed.loading = false;
        });
    },
    manageFeedMasks(feed) {
      this.$emit('manageFeedMasks', feed);
    },
  },
  created() {
    this.fetchFeeds();
  },
};
</script>

<style lang="scss" scoped>
.search-text,
.table-feeds {
  max-width: 1500px;
}
.sort-select {
  width: 150px;
}
</style>
